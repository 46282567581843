import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { BookOpen, FileText, Headphones, Share2, Brain, Upload, Zap, Eye, Volume2 } from 'lucide-react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import Testimonials from '../components/Testimonials';


const features = [
  {
    icon: <FileText className="w-6 h-6" />,
    title: "Smart File Processing",
    description: "Transform any document into digestible knowledge. PDFs, docs, or web articles - we handle it all."
  },
  {
    icon: <Headphones className="w-6 h-6" />,
    title: "Audio Magic",
    description: "Turn lengthy lectures into concise summaries. Generate audio notes for on-the-go learning."
  },
  {
    icon: <BookOpen className="w-6 h-6" />,
    title: "Interactive Learning",
    description: "Auto-generated quizzes and flashcards that adapt to your understanding."
  },
  {
    icon: <Share2 className="w-6 h-6" />,
    title: "Collaborative Growth",
    description: "Share insights and study materials with your circle. Learn better together."
  },
  {
    icon: <Brain className="w-6 h-6" />,
    title: "Personal Learning AI",
    description: "Your AI tutor that evolves with your learning style and pace."
  }
];

const highlights = [
  {
    icon: <Upload className="w-6 h-6" />,
    title: "Smart Upload",
    subtitle: "Any format, any source",
    description: "Import your study materials in any format - PDFs, videos, audio, or documents. Our AI processes everything from academic papers to YouTube lectures."
  },
  {
    icon: <Zap className="w-6 h-6" />,
    title: "Instant Insights",
    subtitle: "AI-powered understanding",
    description: "Get immediate, deep understanding of your materials. Our AI breaks down complex topics and connects ideas across different sources."
  },
  {
    icon: <Eye className="w-6 h-6" />,
    title: "Source Tracking",
    subtitle: "Clear citations, always",
    description: "Every insight comes with precise citations. Jump directly to source materials and see exactly where information comes from."
  },
  {
    icon: <Volume2 className="w-6 h-6" />,
    title: "Audio Learning",
    subtitle: "Learn anywhere, anytime",
    description: "Transform any content into engaging audio summaries. Perfect for learning while commuting, exercising, or multitasking."
  }
];

const testimonials = [
  {
    text: "As a master's student, Edvance has been a game-changer for my thesis work. It's like having a personal research assistant available 24/7! Chatting with research articles and whole folders of content has saved me countless hours of reading and digging through papers.",
    author: "Laurin. S",
    role: "Masters Student",
    avatar: "/laurin.jpg"
  },
  {
    text: "I've been using Edvance for a while now and I must say how much I like it! It's incredibly helpful for summarizing key takeaways from research papers and long videos or podcasts, knowing from the start if the research is what I'm looking for and then allowing me to revisit important points precisely when I need them.",
    author: "Dana S.",
    role: "Economist",
    avatar: "/dana.jpg"
  },
  {
    text: "This is really nice. I don't have any regrets subscribing to Max version. The tool is perfect!!",
    author: "Gracia",
    role: "Researcher",
    avatar: "/gracia.jpg"
  },
  {
    text: "This update 🔥🔥🔥 I have a research lab meeting later today, and we're going to be discussing the implications of AI/tech. I used Edvance to generate a summary and ask a follow-up question & it absolutely delivered. The workflow was much more user-friendly than trying to accomplish the same thing in Bard.",
    author: "Ben D.",
    role: "Post-doc researcher",
    avatar: "/ben.jpg"
  },
  {
    text: "Truly loved interface: it's very straight forward and intuitive vs other ai's. Especially vs having 100 tabs open and copy & pasting back and forth from ChatGPT",
    author: "Natalya Z",
    role: "Pharmaceutical Researcher",
    avatar: "/natalya.jpg"
  },
  {
    text: "I took a 2 hour YouTube video and had the AI in Edvance summarise it. I then asked follow-up questions about the highlighted pieces that got my attention. All told, I was able to collect key takeaways in less than 10 mins. Amazing.",
    author: "DnA",
    role: "Researcher",
    avatar: "/dna.jpg"
  },
  {
    text: "This is the exact tool I've been looking for...I've gathered an overwhelming collection of over 700 bookmarks and 190 open web browser tabs. These resources are my roadmap to research, but navigating through them has become increasingly challenging.",
    author: "Kirsten F.",
    role: "ESG Reporting Analyst",
    avatar: "/kirsten.jpg"
  },
  {
    text: "Edvance is really an excellent tool for understanding my papers. It is just like my personal librarian for the internet!",
    author: "Feng Chun",
    role: "Neuroscience PhD",
    avatar: "/feng.jpg"
  },
  {
    text: "I don't know how you do it but the gap in summary and answer quality to ChatGPT is big. And having real text citations is a game changer.",
    author: "Alex M.",
    role: "Data Scientist",
    avatar: "/alex.jpg"
  }
];

// const Home = () => {
//   const [currentFeature, setCurrentFeature] = useState(0);
//   const [currentTestimonial, setCurrentTestimonial] = useState(0);

//   useEffect(() => {
//     const featureTimer = setInterval(() => {
//       setCurrentFeature((prev) => (prev + 1) % features.length);
//     }, 3000);
    
//     const testimonialTimer = setInterval(() => {
//       setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
//     }, 5000);

//     return () => {
//       clearInterval(featureTimer);
//       clearInterval(testimonialTimer);
//     };
//   }, []);

//   return (
//     <div className="flex flex-col min-h-screen bg-white">
//       <Header />
      
      
//       <main className="flex-grow flex flex-col justify-center items-center px-4 py-12">
      
//       <div className="max-w-4xl text-center mb-16">
  
//   <div className="flex justify-center mb-8">
//     <div className="inline-flex items-center gap-2 px-4 py-2 bg-emerald-50 border border-emerald-100 rounded-full shadow-sm">
//       <span className="text-sm font-medium">✨ 🚀 Powered by GPT-4o, Claude 3.5</span>
//     </div>
//   </div>

 
//   <h1 className="text-7xl font-bold mb-8 leading-tight tracking-tight">
     
//     <span className="text-emerald-500 block mt-2">Transform Information</span>
//     Into Understanding
//   </h1>

 
//   <div className="flex justify-center gap-8 mb-12 text-xl text-gray-700">
//     <p>1) Learn at your natural rhythm</p>
//     <p>2) Master concepts, not just facts</p>
//   </div>


//   <p className="text-2xl text-gray-600 mb-12 max-w-2xl mx-auto leading-relaxed">
//     Everyone's learning style is unique. Let AI adapt to your style and help you grasp complex topics with ease.
//   </p>


//   <div className="mb-12">
//     <div className="flex justify-center -space-x-4 mb-4">
//       <img src="/avatar1.png" alt="Learner" className="w-12 h-12 rounded-full border-4 border-white shadow-sm" />
//       <img src="/avatar2.png" alt="Learner" className="w-12 h-12 rounded-full border-4 border-white shadow-sm" />
//       <img src="/avatar3.png" alt="Learner" className="w-12 h-12 rounded-full border-4 border-white shadow-sm" />
//       <img src="/avatar4.png" alt="Learner" className="w-12 h-12 rounded-full border-4 border-white shadow-sm" />
//       <img src="/avatar5.png" alt="Learner" className="w-12 h-12 rounded-full border-4 border-white shadow-sm" />
//       <div className="w-12 h-12 rounded-full border-4 border-white shadow-sm bg-emerald-50 flex items-center justify-center text-emerald-600 font-medium">
//         +3K
//       </div>
//     </div>
//     <p className="text-gray-500">Joining curious minds worldwide in smarter learning</p>
//   </div>


//   <div className="flex gap-4 justify-center mb-16">
//     <button className="bg-emerald-500 text-white text-lg px-8 py-4 rounded-xl hover:bg-emerald-600 transition duration-300 shadow-lg hover:shadow-xl">
//       Start Your Journey
//     </button>
//     <button className="bg-white text-emerald-600 text-lg px-8 py-4 rounded-xl hover:bg-emerald-50 transition duration-300 border border-emerald-200">
//       See How It Works
//     </button>
//   </div>


//   <div className="flex flex-wrap justify-center gap-3">
//     <div className="bg-white px-4 py-2 rounded-xl border border-gray-100 flex items-center gap-2 hover:border-emerald-200 transition-colors duration-300">
//       <span>🎯 Smart Summaries</span>
//     </div>
//     <div className="bg-white px-4 py-2 rounded-xl border border-gray-100 flex items-center gap-2 hover:border-emerald-200 transition-colors duration-300">
//       <span>🧠 Personalized Learning Paths</span>
//     </div>
//     <div className="bg-white px-4 py-2 rounded-xl border border-gray-100 flex items-center gap-2 hover:border-emerald-200 transition-colors duration-300">
//       <span>💡 Concept Mapping</span>
//     </div>
//     <div className="bg-white px-4 py-2 rounded-xl border border-gray-100 flex items-center gap-2 hover:border-emerald-200 transition-colors duration-300">
//       <span>🎓 Interactive Lessons</span>
//     </div>
//     <div className="bg-white px-4 py-2 rounded-xl border border-gray-100 flex items-center gap-2 hover:border-emerald-200 transition-colors duration-300">
//       <span>🔄 Adaptive Learning</span>
//     </div>
//     <div className="bg-white px-4 py-2 rounded-xl border border-gray-100 flex items-center gap-2 hover:border-emerald-200 transition-colors duration-300">
//       <span>📊 Progress Insights</span>
//     </div>
//   </div>
// </div>  
     
//       </main>

//       <section className="py-20 bg-gray-50">
//   <div className="max-w-6xl mx-auto px-4">
//     <h2 className="text-4xl font-bold text-center mb-16">Powerful features, seamless experience</h2>
//     <div className="grid md:grid-cols-2 gap-12">
//       {highlights.map((highlight, index) => (
//         <div key={index} className="bg-white rounded-xl p-8 shadow-sm hover:shadow-md transition-shadow duration-300">
//           <div className="w-12 h-12 bg-emerald-100 rounded-lg flex items-center justify-center text-emerald-600 mb-6">
//             {highlight.icon}
//           </div>
//           <div className="space-y-2">
//             <h3 className="text-2xl font-semibold">{highlight.title}</h3>
//             <p className="text-emerald-600 font-medium">{highlight.subtitle}</p>
//             <p className="text-gray-600 mt-4 leading-relaxed">
//               {highlight.description}
//             </p>
//           </div>
//         </div>
//       ))}
//     </div>
//   </div>
// </section>

//       <Testimonials />
//       <div className="mt-16 text-center">
//           <p className="text-sm text-gray-400">
//             Trusted by students and professionals at
//           </p>
//           <div className="flex gap-8 mt-4 justify-center text-gray-400">
//             <span className="font-semibold">Harvard</span>
//             <span className="font-semibold">Stanford</span>
//             <span className="font-semibold">MIT</span>
//             <span className="font-semibold">Google</span>
//           </div>
//         </div>

//       <Footer />
//     </div>
//   );
// };

const Home = () => {
  const [currentFeature, setCurrentFeature] = useState(0);
  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  useEffect(() => {
    const featureTimer = setInterval(() => {
      setCurrentFeature((prev) => (prev + 1) % features.length);
    }, 3000);
    
    const testimonialTimer = setInterval(() => {
      setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
    }, 5000);

    return () => {
      clearInterval(featureTimer);
      clearInterval(testimonialTimer);
    };
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <Header />
      
      {/* Hero Section */}
      <main className="flex-grow flex flex-col justify-center items-center px-4 py-8 sm:py-12">
        <div className="max-w-4xl text-center mb-8 sm:mb-16">
          {/* AI Badge */}
          <div className="flex justify-center mb-6 sm:mb-8">
            <div className="inline-flex items-center gap-2 px-3 sm:px-4 py-1.5 sm:py-2 bg-emerald-50 border border-emerald-100 rounded-full shadow-sm">
              <span className="text-xs sm:text-sm font-medium">✨ 🚀 Powered by GPT-4o, Claude 3.5</span>
            </div>
          </div>

          {/* Main Headline */}
          <h1 className="text-4xl sm:text-6xl md:text-7xl font-bold mb-6 sm:mb-8 leading-tight tracking-tight">
            <span className="text-emerald-500 block mt-2">Transform Information</span>
            Into Understanding
          </h1>

          {/* Key Benefits */}
          <div className="flex flex-col sm:flex-row justify-center gap-4 sm:gap-8 mb-8 sm:mb-12 text-lg sm:text-xl text-gray-700">
            <p>1) Learn at your natural rhythm</p>
            <p>2) Master concepts, not just facts</p>
          </div>

          {/* Tagline */}
          <p className="text-lg sm:text-2xl text-gray-600 mb-8 sm:mb-12 max-w-2xl mx-auto leading-relaxed px-4">
            Everyone's learning style is unique. Let AI adapt to your style and help you grasp complex topics with ease.
          </p>

          {/* Social Proof */}
          <div className="mb-8 sm:mb-12">
            <div className="flex justify-center -space-x-4 mb-4">
              <img src="/avatar1.png" alt="Learner" className="w-8 h-8 sm:w-12 sm:h-12 rounded-full border-2 sm:border-4 border-white shadow-sm" />
              <img src="/avatar2.png" alt="Learner" className="w-8 h-8 sm:w-12 sm:h-12 rounded-full border-2 sm:border-4 border-white shadow-sm" />
              <img src="/avatar3.png" alt="Learner" className="w-8 h-8 sm:w-12 sm:h-12 rounded-full border-2 sm:border-4 border-white shadow-sm" />
              <img src="/avatar4.png" alt="Learner" className="w-8 h-8 sm:w-12 sm:h-12 rounded-full border-2 sm:border-4 border-white shadow-sm" />
              <img src="/avatar5.png" alt="Learner" className="w-8 h-8 sm:w-12 sm:h-12 rounded-full border-2 sm:border-4 border-white shadow-sm" />
              <div className="w-8 h-8 sm:w-12 sm:h-12 rounded-full border-2 sm:border-4 border-white shadow-sm bg-emerald-50 flex items-center justify-center text-emerald-600 text-sm sm:text-base font-medium">
                +3K
              </div>
            </div>
            <p className="text-sm sm:text-base text-gray-500">Joining curious minds worldwide in smarter learning</p>
          </div>

          {/* CTA Buttons */}
          <div className="flex flex-col sm:flex-row gap-4 justify-center mb-8 sm:mb-16 px-4">
            <button className="w-full sm:w-auto bg-emerald-500 text-white text-base sm:text-lg px-6 sm:px-8 py-3 sm:py-4 rounded-xl hover:bg-emerald-600 transition duration-300 shadow-lg hover:shadow-xl">
              Start Your Journey
            </button>
            <button className="w-full sm:w-auto bg-white text-emerald-600 text-base sm:text-lg px-6 sm:px-8 py-3 sm:py-4 rounded-xl hover:bg-emerald-50 transition duration-300 border border-emerald-200">
              See How It Works
            </button>
          </div>

          {/* Feature Tags */}
          <div className="flex flex-wrap justify-center gap-2 sm:gap-3 px-4">
            {[
              "🎯 Smart Summaries",
              "🧠 Personalized Learning",
              "💡 Concept Mapping",
              "🎓 Interactive Lessons",
              "🔄 Adaptive Learning",
              "📊 Progress Insights"
            ].map((feature, index) => (
              <div key={index} className="bg-white px-3 sm:px-4 py-1.5 sm:py-2 rounded-xl border border-gray-100 flex items-center gap-2 hover:border-emerald-200 transition-colors duration-300 text-sm sm:text-base">
                <span>{feature}</span>
              </div>
            ))}
          </div>
        </div>
      </main>

      {/* Highlights Section */}
      <section className="py-12 sm:py-20 bg-gray-50">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-3xl sm:text-4xl font-bold text-center mb-8 sm:mb-16">
            Powerful features, seamless experience
          </h2>
          <div className="grid md:grid-cols-2 gap-6 sm:gap-12">
            {highlights.map((highlight, index) => (
              <div key={index} className="bg-white rounded-xl p-6 sm:p-8 shadow-sm hover:shadow-md transition-shadow duration-300">
                <div className="w-10 h-10 sm:w-12 sm:h-12 bg-emerald-100 rounded-lg flex items-center justify-center text-emerald-600 mb-4 sm:mb-6">
                  {highlight.icon}
                </div>
                <div className="space-y-2">
                  <h3 className="text-xl sm:text-2xl font-semibold">{highlight.title}</h3>
                  <p className="text-emerald-600 font-medium text-sm sm:text-base">{highlight.subtitle}</p>
                  <p className="text-gray-600 mt-2 sm:mt-4 leading-relaxed text-sm sm:text-base">
                    {highlight.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <Testimonials />

      {/* Trusted By Section */}
      <div className="mt-8 sm:mt-16 text-center px-4">
        <p className="text-xs sm:text-sm text-gray-400">
          Trusted by students and professionals at
        </p>
        <div className="flex flex-wrap gap-4 sm:gap-8 mt-3 sm:mt-4 justify-center text-gray-400">
          {["Harvard", "Stanford", "MIT", "Google"].map((org, index) => (
            <span key={index} className="font-semibold text-sm sm:text-base">{org}</span>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
};
export default Home;