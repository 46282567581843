// src/App.js
import React from "react";
import Home from "./pages/Home";

// Main App Component
function App() {
  return <Home />;
}

export default App;
