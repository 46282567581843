// src/components/Testimonials.jsx
import React, { useState, useEffect, useRef } from 'react';

const testimonials = [
  {
    text: "As a master's student, Edvance has been a game-changer for my thesis work. It's like having a personal research assistant available 24/7! Chatting with research articles and whole folders of content has saved me countless hours of reading and digging through papers.",
    author: "Laurin. S",
    role: "Masters Student",
    avatar: "/avatar1.png"
  },
  {
    text: "I've been using Edvance for a while now and I must say how much I like it! It's incredibly helpful for summarizing key takeaways from research papers and long videos or podcasts, knowing from the start if the research is what I'm looking for and then allowing me to revisit important points precisely when I need them.",
    author: "Dana S.",
    role: "Economist",
    avatar: "/avatar2.png"
  },
  {
    text: "This is really nice. I don't have any regrets subscribing to Max version. The tool is perfect!!",
    author: "Gracia",
    role: "Researcher",
    avatar: "/avatar3.png"
  },
  {
    text: "This update 🔥🔥🔥 I have a research lab meeting later today, and we're going to be discussing the implications of AI/tech. I used Edvance to generate a summary and ask a follow-up question & it absolutely delivered. The workflow was much more user-friendly than trying to accomplish the same thing in Bard.",
    author: "Ben D.",
    role: "Post-doc researcher",
    avatar: "/avatar4.png"
  },
  {
    text: "Truly loved interface: it's very straight forward and intuitive vs other ai's. Especially vs having 100 tabs open and copy & pasting back and forth from ChatGPT",
    author: "Natalya Z",
    role: "Pharmaceutical Researcher",
    avatar: "/avatar5.png"
  },
  {
    text: "I took a 2 hour YouTube video and had the AI in Edvance summarise it. I then asked follow-up questions about the highlighted pieces that got my attention. All told, I was able to collect key takeaways in less than 10 mins. Amazing.",
    author: "DnA",
    role: "Researcher",
    avatar: "/avatar6.png"
  },
  {
    text: "This is the exact tool I've been looking for...I've gathered an overwhelming collection of over 700 bookmarks and 190 open web browser tabs. These resources are my roadmap to research, but navigating through them has become increasingly challenging.",
    author: "Kirsten F.",
    role: "ESG Reporting Analyst",
    avatar: "/avatar1.png"
  },
  {
    text: "Edvance is really an excellent tool for understanding my papers. It is just like my personal librarian for the internet!",
    author: "Feng Chun",
    role: "Neuroscience PhD",
    avatar: "/avatar2.png"
  },
  {
    text: "I don't know how you do it but the gap in summary and answer quality to ChatGPT is big. And having real text citations is a game changer.",
    author: "Alex M.",
    role: "Data Scientist",
    avatar: "/avatar3.png"
  }
];

// const Testimonials = () => {
//   const [scrollPosition, setScrollPosition] = useState(0);
//   const scrollRef = useRef(null);
  
//   useEffect(() => {
//     const scrollContainer = scrollRef.current;
//     let animationFrameId;
//     let scrolling = true;
    
//     const scroll = () => {
//       if (!scrollContainer || !scrolling) return;
      
//       setScrollPosition(prev => {
//         const newPosition = prev + 0.5; // Reduced speed
//         if (newPosition >= scrollContainer.scrollWidth - scrollContainer.clientWidth) {
//           return 0;
//         }
//         return newPosition;
//       });
      
//       scrollContainer.scrollLeft = scrollPosition;
//       animationFrameId = requestAnimationFrame(scroll);
//     };

//     animationFrameId = requestAnimationFrame(scroll);
    
//     // Pause scrolling on hover
//     const handleMouseEnter = () => {
//       scrolling = false;
//     };

//     const handleMouseLeave = () => {
//       scrolling = true;
//       requestAnimationFrame(scroll);
//     };

//     scrollContainer.addEventListener('mouseenter', handleMouseEnter);
//     scrollContainer.addEventListener('mouseleave', handleMouseLeave);
    
//     return () => {
//       cancelAnimationFrame(animationFrameId);
//       if (scrollContainer) {
//         scrollContainer.removeEventListener('mouseenter', handleMouseEnter);
//         scrollContainer.removeEventListener('mouseleave', handleMouseLeave);
//       }
//     };
//   }, [scrollPosition]);

//   return (
//     <section className="py-20 bg-white">
//       <div className="max-w-6xl mx-auto px-4">
//         <div className="flex items-center justify-center mb-2">
//           <span className="text-4xl">💝</span>
//         </div>
//         <h2 className="text-5xl font-bold text-center mb-4">
//           Loved by researchers, students & analysts
//         </h2>
//         <p className="text-xl text-center text-gray-600 mb-16">
//           Don't just take our word for it
//         </p>

//         <div className="relative overflow-hidden">
//           <div 
//             ref={scrollRef}
//             className="flex gap-6 whitespace-nowrap overflow-x-hidden"
//           >
//             {[...testimonials, ...testimonials].map((testimonial, index) => (
//               <div 
//                 key={index} 
//                 className="inline-flex flex-none w-[350px] bg-white rounded-2xl p-6 shadow-[0_2px_8px_rgba(0,0,0,0.08)] hover:shadow-[0_4px_16px_rgba(0,0,0,0.12)] transition-all duration-300 hover:-translate-y-1"
//               >
//                 <div className="overflow-hidden">
//                   <p className="text-gray-800 mb-6 text-[15px] leading-relaxed whitespace-normal">
//                     {testimonial.text}
//                   </p>
//                   <div className="flex items-center gap-3">
//                     <img
//                       src={testimonial.avatar}
//                       alt={testimonial.author}
//                       className="w-10 h-10 rounded-full"
//                     />
//                     <div>
//                       <p className="font-medium text-[15px]">{testimonial.author}</p>
//                       <p className="text-gray-500 text-sm">{testimonial.role}</p>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
          
//           {/* Gradient overlays */}
//           <div className="absolute left-0 top-0 bottom-0 w-20 bg-gradient-to-r from-white to-transparent pointer-events-none" />
//           <div className="absolute right-0 top-0 bottom-0 w-20 bg-gradient-to-l from-white to-transparent pointer-events-none" />
//         </div>
//       </div>
//     </section>
//   );
// };

// Testimonials.jsx
const Testimonials = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const scrollRef = useRef(null);
  
  useEffect(() => {
    const scrollContainer = scrollRef.current;
    let animationFrameId;
    let scrolling = true;
    
    const scroll = () => {
      if (!scrollContainer || !scrolling) return;
      
      setScrollPosition(prev => {
        const newPosition = prev + 0.5;
        if (newPosition >= scrollContainer.scrollWidth - scrollContainer.clientWidth) {
          return 0;
        }
        return newPosition;
      });
      
      scrollContainer.scrollLeft = scrollPosition;
      animationFrameId = requestAnimationFrame(scroll);
    };

    animationFrameId = requestAnimationFrame(scroll);
    
    const handleMouseEnter = () => {
      scrolling = false;
    };

    const handleMouseLeave = () => {
      scrolling = true;
      requestAnimationFrame(scroll);
    };

    scrollContainer.addEventListener('mouseenter', handleMouseEnter);
    scrollContainer.addEventListener('mouseleave', handleMouseLeave);
    
    return () => {
      cancelAnimationFrame(animationFrameId);
      if (scrollContainer) {
        scrollContainer.removeEventListener('mouseenter', handleMouseEnter);
        scrollContainer.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, [scrollPosition]);

  return (
    <section className="py-12 sm:py-20 bg-white">
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex items-center justify-center mb-2">
          <span className="text-3xl sm:text-4xl">💝</span>
        </div>
        <h2 className="text-3xl sm:text-5xl font-bold text-center mb-4">
          Loved by researchers, students & analysts
        </h2>
        <p className="text-lg sm:text-xl text-center text-gray-600 mb-8 sm:mb-16">
          Don't just take our word for it
        </p>

        <div className="relative overflow-hidden">
          <div 
            ref={scrollRef}
            className="flex gap-4 sm:gap-6 whitespace-nowrap overflow-x-hidden"
          >
            {[...testimonials, ...testimonials].map((testimonial, index) => (
              <div 
                key={index} 
                className="inline-flex flex-none w-[280px] sm:w-[350px] bg-white rounded-2xl p-4 sm:p-6 shadow-[0_2px_8px_rgba(0,0,0,0.08)] hover:shadow-[0_4px_16px_rgba(0,0,0,0.12)] transition-all duration-300 hover:-translate-y-1"
              >
                <div className="overflow-hidden">
                  <p className="text-gray-800 mb-4 sm:mb-6 text-sm sm:text-[15px] leading-relaxed whitespace-normal">
                    {testimonial.text}
                  </p>
                  <div className="flex items-center gap-3">
                    <img
                      src={testimonial.avatar}
                      alt={testimonial.author}
                      className="w-8 h-8 sm:w-10 sm:h-10 rounded-full"
                    />
                    <div>
                      <p className="font-medium text-sm sm:text-[15px]">{testimonial.author}</p>
                      <p className="text-gray-500 text-xs sm:text-sm">{testimonial.role}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          
          <div className="absolute left-0 top-0 bottom-0 w-12 sm:w-20 bg-gradient-to-r from-white to-transparent pointer-events-none" />
          <div className="absolute right-0 top-0 bottom-0 w-12 sm:w-20 bg-gradient-to-l from-white to-transparent pointer-events-none" />
        </div>
      </div>
    </section>
  );
};

export default Testimonials;