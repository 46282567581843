// import React from 'react';

// const Header = () => {
//   return (
//     <header className="border-b border-gray-100 sticky top-0 bg-white/80 backdrop-blur-md z-50">
//       <div className="max-w-7xl mx-auto">
//         <div className="flex items-center justify-between h-16 px-6 md:px-8">
//           {/* Logo */}
//           <div className="flex-1 flex justify-start">
//             <a href="/" className="text-2xl font-bold text-gray-900 hover:text-emerald-600 transition-colors">
//             Edvance
//             </a>
//           </div>

//           {/* Navigation - Centered */}
//           <nav className="flex-1 hidden md:flex items-center justify-center">
//             <div className="flex space-x-8">
//               <a 
//                 href="#" 
//                 className="text-sm font-medium text-gray-700 hover:text-emerald-600 transition-colors relative group"
//               >
//                 Overview
//                 <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-emerald-500 group-hover:w-full transition-all duration-300" />
//               </a>
//               <a 
//                 href="#" 
//                 className="text-sm font-medium text-gray-700 hover:text-emerald-600 transition-colors relative group"
//               >
//                 Business
//                 <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-emerald-500 group-hover:w-full transition-all duration-300" />
//               </a>
//             </div>
//           </nav>

//           {/* CTA Button */}
//           <div className="flex-1 flex justify-end">
//             <button className="bg-emerald-50 text-emerald-600 px-5 py-2.5 rounded-lg hover:bg-emerald-100 transition-all duration-300 text-sm font-medium shadow-sm hover:shadow flex items-center gap-2">
//               Try Edvance
//               <span className="text-xs">→</span>
//             </button>
//           </div>
//         </div>
//       </div>
//     </header>
//   );
// };



// Header.jsx
import React, { useState } from 'react';
import { Menu, X } from 'lucide-react';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="border-b border-gray-100 sticky top-0 bg-white/80 backdrop-blur-md z-50">
      <div className="max-w-7xl mx-auto">
        <div className="flex items-center justify-between h-16 px-4 sm:px-6 md:px-8">
          {/* Logo */}
          {/* <div className="flex-shrink-0">
            <a href="/" className="text-xl sm:text-2xl font-bold text-gray-900 hover:text-emerald-600 transition-colors">
              Edvance
            </a>
          </div> */}
          <div className="flex-shrink-0">
  <a href="/" className="text-2xl sm:text-3xl font-bold text-gray-900 hover:text-emerald-600 transition-colors">
    Edvance
  </a>
</div>

          {/* Mobile menu button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="p-2 rounded-md text-gray-600 hover:text-gray-900 hover:bg-gray-100"
            >
              {isMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </button>
          </div>

          {/* Desktop Navigation */}
          <nav className="hidden md:flex items-center justify-center flex-1">
            <div className="flex space-x-8">
              <a href="#" className="text-sm font-medium text-gray-700 hover:text-emerald-600 transition-colors relative group">
                Overview
                <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-emerald-500 group-hover:w-full transition-all duration-300" />
              </a>
              <a href="#" className="text-sm font-medium text-gray-700 hover:text-emerald-600 transition-colors relative group">
                Business
                <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-emerald-500 group-hover:w-full transition-all duration-300" />
              </a>
            </div>
          </nav>

          {/* CTA Button - Hidden on mobile */}
          <div className="hidden md:flex items-center">
            <button className="bg-emerald-50 text-emerald-600 px-5 py-2.5 rounded-lg hover:bg-emerald-100 transition-all duration-300 text-sm font-medium shadow-sm hover:shadow flex items-center gap-2">
              Try Edvance
              <span className="text-xs">→</span>
            </button>
          </div>
        </div>

        {/* Mobile menu */}
        {isMenuOpen && (
          <div className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              <a
                href="#"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-emerald-600 hover:bg-gray-50"
              >
                Overview
              </a>
              <a
                href="#"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-emerald-600 hover:bg-gray-50"
              >
                Business
              </a>
              <button className="w-full mt-4 bg-emerald-50 text-emerald-600 px-5 py-2.5 rounded-lg hover:bg-emerald-100 transition-all duration-300 text-sm font-medium shadow-sm hover:shadow flex items-center justify-center gap-2">
                Try Edvance
                <span className="text-xs">→</span>
              </button>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;