import React from "react";

// const Footer = () => {
//     return (
//       <footer className="border-t border-gray-100 py-8 px-4">
//         <div className="max-w-7xl mx-auto flex justify-between items-center">
//           <div className="text-sm text-gray-500">
//             © 2024 Edvance. All rights reserved.
//           </div>
//           <div className="flex space-x-6">
//             <a href="https://twitter.com/Edvance" className="text-gray-400 hover:text-emerald-500 transition">
//               Twitter
//             </a>
//             <a href="https://instagram.com/Edvance" className="text-gray-400 hover:text-emerald-500 transition">
//               Instagram
//             </a>
//           </div>
//         </div>
//       </footer>
//     );
//   };
// Footer.jsx
const Footer = () => {
  return (
    <footer className="border-t border-gray-100 py-8 px-4">
      <div className="max-w-7xl mx-auto flex flex-col sm:flex-row justify-between items-center gap-4">
        <div className="text-sm text-gray-500 text-center sm:text-left">
          © 2024 Edvance. All rights reserved.
        </div>
        <div className="flex space-x-6">
          <a href="https://twitter.com/Edvance" className="text-gray-400 hover:text-emerald-500 transition">
            Twitter
          </a>
          <a href="https://instagram.com/Edvance" className="text-gray-400 hover:text-emerald-500 transition">
            Instagram
          </a>
        </div>
      </div>
    </footer>
  );
};


export default Footer;
